import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

// Conditional syntax in JSX
// https://stackoverflow.com/questions/53508119/what-is-the-syntax-for-a-simple-if-conditional-in-gatsby-js


const PrivacyPage = () => {


  return (
    <Layout>
      <SEO title="Om Gratis.no" description={""}/>

      <div>

        <section className="section novi-bg novi-bg-img section-lg bg-white section-top-shadow">
          <div className="container">
            <h2 style={{marginBottom:'30px'}} className="text-center text-sm-left">Om <span className="h2-style">Gratis.no</span></h2>

            <p className="infoPageParagraph">
              Gratis.no er en oversiktsside med innhold som lenker til ulike kommersielle tilbud som velkomstgaver, velkomsttilbud, vareprøver,
              produktprøver, gratisprøver osv. Alle tilbud på våre sider er tilbudt av tredjeparter og henvendelser
              vedrørende slike tilbud må rettes til de respektive tilbydere.
            </p>
            <p className="infoPageParagraph">
              Siden er utviklet av og driftes av selskapet Wonder Digital Media AS med org.nr. 915466923.
            </p>

            <p className="infoPageParagraph">
              Vi anmoder alle som bruker sidene våre til å sette seg inn i:
            </p>

            <ul className="infoPageList">
              <li><Link to="/brukerbetingelser/">Våre brukerbetingelser</Link></li>
              <li><Link to="/personvern/">Vår personvernserklæring</Link></li>
            </ul>

            <p className="infoPageParagraph">
              Henvendelser til oss kan rettes til post@gratis.no. Vi besvarer alle relevante henvendelser så fort det lar seg gjøre.
            </p>

            <div className="mt-xl-40 mt-50 text-center text-sm-right"><Link
              className="button button-icon button-icon-right button-black" href="/"><span
              className="icon novi-icon fa fa-chevron-right"/>Til forsiden</Link></div>
          </div>
        </section>

      </div>
    </Layout>
  )
}

export default PrivacyPage


